<template>
  <div class="login">
    <div class="nav">
      <navTop :show="false"></navTop>
    </div>
    <div class="allBox" :style="{background:bannerList.length>0 ?bannerList[activeIndex].bannerColour :'#ffffff'}">
      <div class="swiper-container" id="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index" @click="$router.push('/home')">
            <div :style="{backgroundImage: `url(${item.bannerImg})`}"></div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="contentBox">
        <div class="box" :style="{height:isShow==1||isShow==4?'468px':'590px'}">
          <div style=" padding: 0 60px;box-sizing: border-box;">
            <div class="title">
              <!--            牧予生活-->
            </div>
            <div v-if="isShow == 1">
              <div class="boxNav">
                <div>登录</div>
              </div>
              <div class="boxContent">
                <el-form :model="loginParmas" :rules="rules" ref="ruleForm1" label-width="0px" class="demo-ruleForm">
                  <el-form-item label="" prop="username">
                    <el-input v-model.trim="loginParmas.username" placeholder="手机号"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="password">
                    <el-input autoComplete="off" v-model.trim="loginParmas.password" type="password" placeholder="密码"></el-input>
                  </el-form-item>

                </el-form>


              </div>
              <div class="btns">
                <div class="register" @click="regis(2)">
                  注册
                </div>
                <div class="segmentation"></div>
                <div class="forgetPassword" @click="regis(4)">
                  忘记密码
                </div>
              </div>
              <div class="btn" @click="login('ruleForm1')">
                <button type="button">登录</button>
              </div>
            </div>
            <div v-if="isShow == 2 || isShow == 3">
              <div class="boxNavList">
                <div :class="['list', isShow == 2 ? 'act' : '']" @click="navChange(2)">
                  企业
                </div>
                <div :class="['list', isShow == 3 ? 'act' : '']" @click="navChange(3)">
                  个人
                </div>
              </div>
              <div v-if="isShow == 2">
                <div class="boxContent">
                  <el-form :model="registerParmas" :rules="rules1" ref="ruleForm2" label-width="0px"
                           class="demo-ruleForm">
                    <el-form-item label="" prop="username" class="MarginBottom22">
                      <el-input v-model.trim="registerParmas.username" placeholder="企业名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="nickName" class="MarginBottom22">
                      <el-input v-model.trim="registerParmas.nickName" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="userPhone" class="MarginBottom22">
                      <el-input v-model.trim="registerParmas.userPhone" placeholder="手机号"></el-input>
                    </el-form-item>

                    <el-form-item label="" prop="password" class="MarginBottom22">
                      <el-input autoComplete="off" v-model.trim="registerParmas.password" type="password" placeholder="密码"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phoneCode" class="MarginBottom22">
                      <div class="smsCode">
                        <el-input v-model.trim="registerParmas.phoneCode" placeholder="验证码"></el-input>
                        <span @click="getCode">{{ codeText }}</span>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div v-if="isShow == 3">
                <div class="boxContent">
                  <el-form :model="registerParmas" :rules="rules1" ref="ruleForm3" label-width="0px"
                           class="demo-ruleForm">

                    <el-form-item label="" prop="nickName" class="MarginBottom22">
                      <el-input v-model.trim="registerParmas.nickName" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="userPhone" class="MarginBottom22">
                      <el-input v-model.trim="registerParmas.userPhone" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password" class="MarginBottom22">
                      <el-input autoComplete="off" v-model.trim="registerParmas.password" type="password" placeholder="密码"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phoneCode" class="MarginBottom22">
                      <div class="smsCode">
                        <el-input v-model.trim="registerParmas.phoneCode" placeholder="验证码"></el-input>
                        <span @click="getCode">{{ codeText }}</span>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="agreement" @click="isChecked = !isChecked">
                <span :class="['icon', isChecked ? 'yes' : 'no']"></span>
                已阅读并同意<span class="text">《隐私保护声明》</span>
              </div>
              <div class="btn" @click="register">
                <button type="button">注册</button>
              </div>
              <div class="switchover" @click="isShow = 1">
                已有账号，去<span>登录</span>
              </div>
            </div>
            <div v-if="isShow == 4">
              <div class="boxNav">
                修改密码
              </div>
              <div class="boxContent">
                <el-form :model="forgetParmas" :rules="rules2" ref="ruleForm4" label-width="0px" class="demo-ruleForm">
                  <div>
                    <el-form-item label="" prop="userPhone" class="MarginBottom22">
                      <el-input v-model.trim="forgetParmas.userPhone" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phoneCode" class="MarginBottom22">
                      <div class="smsCode">
                        <el-input v-model.trim="forgetParmas.phoneCode" placeholder="验证码"></el-input>
                        <span @click="getCode">{{ codeText }}</span>
                      </div>
                    </el-form-item>
                    <el-form-item label="" prop="password" class="MarginBottom22">
                      <el-input autoComplete="off" type="password" v-model.trim="forgetParmas.password" placeholder="密码"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="newPassword" class="MarginBottom22">
                      <el-input autoComplete="off" v-model.trim="forgetParmas.newPassword" type="password" placeholder="再次输入密码"></el-input>
                    </el-form-item>
                  </div>

                </el-form>
              </div>
              <div class="btn" @click="forgetConfirm('ruleForm4')">
                <button type="button">提交</button>
              </div>
              <div class="switchover" @click="regis(1)">
                  已有账号，去<span>登录</span>
              </div>
            </div>
          </div>
          <div class="tip">
            <div class="phone">
              <img src="../assets/img/phone.png"/>
              <span> 023-41666182</span>
            </div>
            <div class="time">
              <img src="../assets/img/time.png"/>
              <span>
            周一至周五   9:00 ~ 18:30
          </span>

            </div>
          </div>

        </div>
      </div>

    </div>

    <myBase background="#FFFFFF" color="#333333"></myBase>
    <canvas id="canvas" width="1000px" height="500px" style="display: none"></canvas>

  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import myBase from '@/components/myBase.vue';
import navTop from '../components/navTop';
import initImg from "../plugin/getImgColor";
import analyze from 'rgbaster'

export default {
  name: 'login',
  components: {
    myBase,
    navTop,
  },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.forgetParmas.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      swiperObj: null,
      isShow: 1,
      isChecked: false,
      time: 60,
      timer: null,
      codeText: '获取',
      registerParmas: {
        userType: '',
        userName: '',
        nickName: '',
        userPhone: '',
        phoneCode: '',
        password: '',
      },
      loginParmas: {
        username: '',
        password: '',
      },
      forgetParmas: {
        userPhone: '',
        phoneCode: '',
        password: '',
        newPassword: '',
      },
      bannerList: [],
      imgUrl: '',
      activeIndex: 0,
      rules: {
        username: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      rules1: {
        username: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
        ],
        nickName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        userPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      rules2: {
        userPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        newPassword: [
          {validator: validatePass2, trigger: 'blur'},
        ],
      },

    };

  },
  created() {
    this.getBanner();

  },
  mounted() {

  },
  watch: {
    '$route':{
      handler(newV){
        if(newV.path == '/login'){
          this.isShow = newV.query.type?newV.query.type:1;
        }
      },
      immediate:true
    }
  },
  methods: {
    regis(nav) {
      this.clearValidate();
      this.isShow = nav
    },
    getBanner() {
      this.$request.HttpGet('/api/banner', {page: 1, pageSize: 100}).then(res => {
        this.bannerList = res.data.list || [];
        this.imgUrl = this.bannerList[0].bannerImg
        this.$nextTick(async () => {
          //获取图片颜色组
          // const result = await analyze('https://muyu-tech.cn/local/files/b623a5b626f6479d162bea2ee35ead42_20220125141822.jpeg', {ignore: ['rgb(255,255,255)', 'rgb(0,0,0)']})
          // debugger
          this.swiperObj = new Swiper('#swiper', {
            loop: false,
            pagination: '.swiper-pagination',
            autoplay: 5000,
            paginationClickable: true,
            onSlideChangeStart: (swiper) => {
              this.activeIndex = swiper.activeIndex
            },
          });
        });
      });
    },
    register() {
      let parms = {};
      let str = "ruleForm2"
      if (this.isShow == 2) {
        str = "ruleForm2"
      }
      if (this.isShow == 3) {
        str = "ruleForm3"
      }
      this.registerParmas.userType = this.isShow == 2 ? 20 : 10;
      this.$refs[str].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/api/register', this.registerParmas).then((res) => {
            this.$toast('注册成功');
            this.isShow = 1;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    login(formName) {
      // if (!this.loginParmas.username) {
      //   this.$toast('请输入手机号');
      //   return;
      // }
      // if (!this.loginParmas.password) {
      //   this.$toast('请输入密码');
      //   return;
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/api/login', this.loginParmas).then((res) => {
            window.localStorage.setItem('token', res.data.token);
            window.localStorage.setItem('userInfo', JSON.stringify(res.data));
            if (res.data.user.userRecycle == 0) {
              this.$router.push({path: '/informationPerfect'});
            } else if (res.data.user.userRecycle == 20) {
              this.$router.push({path: '/modifyInformation'})
            } else {
              this.$router.push({path: '/personalCenter'});
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    forgetConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/api/notInPassword', this.forgetParmas).then((res) => {
            this.$toast('修改成功，请重新登录');
            this.isShow = 1;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },
    getCode() {
      if (!this.registerParmas.userPhone) {
        this.$refs.ruleForm2 && this.$refs.ruleForm2.validateField('userPhone');
        this.$refs.ruleForm3 && this.$refs.ruleForm3.validateField('userPhone');
        // this.$toast('请输入手机号');
        return;
      }
      this.$request.HttpGet('/api/sendSms', {
        userPhone: this.registerParmas.userPhone,
      }).then(res => {
        this.$toast('短信发送成功,注意查收');
        this.countDown();
      });
    },
    getForgetCode() {
      if (!this.forgetParmas.userPhone) {
        this.$toast('请输入手机号');
        return;
      }
      this.$request.HttpGet('/api/sendSms', {
        userPhone: this.forgetParmas.userPhone,
      }).then(res => {
        this.$toast('短信发送成功,注意查收');
        this.countDown();
      });
    },
    countDown() {
      if (this.timer) {
        return;
      }
      this.timer = setInterval(() => {
        this.time--;
        this.codeText = this.time;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeText = "获取";
        }
      }, 1000);
    },
    navChange(e) {
      this.isShow = e;
      this.clearValidate()
      for (let item in this.registerParmas) {
        if (item !== 'userType') {
          this.registerParmas[item] = ''
        }
      }

    },
    clearValidate() {

      this.$refs.ruleForm1 && this.$refs.ruleForm1.clearValidate()
      this.$refs.ruleForm2 && this.$refs.ruleForm2.clearValidate()
      this.$refs.ruleForm3 && this.$refs.ruleForm3.clearValidate()
      this.$refs.ruleForm4 && this.$refs.ruleForm4.clearValidate()
    },
  },
};
</script>

<style lang="scss" scoped>

.login {
  position: relative;
  background-color: #ffffff;
  min-height: 100vh;

  .nav {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
    background: #FFFFFF;
  }

  .allBox {
    position: relative;
    min-width: 1200px;
    //max-width: 1400px;
    margin: 0 auto;

    .swiper-container {
      // height: 760px;
      height: 778px;
      min-height: 778px;
      width: 1400px;

      .swiper-slide {
        div {
          min-width: 1200px;
          height: calc(100vh - 210px);
          min-height: 778px;

          //background-size: 1400px 778px;
          object-fit: fill;
          // height: 760px;
        }
      }
    }

    .contentBox {
      min-width: 1400px;
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;

      .box {
        min-width: 480px;
        min-height: 377px;
        background: #FFFFFF;
        border: 1px solid rgba(#000000,0.06);
        position: absolute;
        // right: 200px;
        // top: 120px;
        top: 20%;
        //transform: translateY(-50%);
        z-index: 100;

        .tip {
          //margin-top: 80px;
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(0, 0, 0, 0.06);
          position: absolute;
          left: 0;
          bottom: 0;

          .phone, .time {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 40px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);

            img {
              width: 10px;
              height: 10px;
              margin-right: 4px;
              // vertical-align: middle;
            }
          }

          .phone {
            border-right: 1px solid rgba(0, 0, 0, 0.06);
          }

          div:hover {
            cursor: pointer;
            color: #3D7EFF;
          }
        }

        .title {
          height: 30px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          color: #333333;
        }

        .boxNav {
          color: #666666;

          //padding-bottom: 5px;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);

          div {
            width: 50%;
            text-align: left;
            //border-bottom: 1px solid #C8C8C8;
          }

          .actNav {
            border-bottom: 2px solid #3D7EFF;
          }
        }

        .boxNavList {
          font-size: 16px;
          color: #666666;
          border-bottom: 1px solid rgba(#000000,0.06);
          padding-bottom: 15px;
          display: flex;

          .list {
            flex: 1;
            text-align: center;
            cursor: pointer;

            &.act {
              position: relative;
              color: #3D7EFF;

              &:before {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: -15px;
                height: 2px;
                width: 100%;
                background: #3D7EFF;
              }
            }
          }
        }

        .boxContent {
          margin-top: 20px;

          .accout,
          .password {
            width: 100%;
            height: 42px;
            background: #FFFFFF;
            border: 1px solid #C8C8C8;
            line-height: 42px;
            box-sizing: border-box;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
          }

          .smsCode {
            flex: 1;
            height: 42px;
            background: #FFFFFF;
            //border: 1px solid #C8C8C8;
            background: #FFFFFF;
            line-height: 42px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            align-items: center;
            //margin-bottom: 15px;

            input {
              flex: 1;
              box-sizing: border-box;
              border: 1px solid #C8C8C8 !important;
            }

            input:focus {
              border: 1px solid #3D7EFF !important;
            }

            span {
              width: 120px;
              height: 38px;
              line-height: 38px;
              font-size: 14px;
              color: #3D7EFF;
              cursor: pointer;
              text-align: center;
              margin-left: 10px;
              border: 1px solid #3D7EFF;
              margin-top: 3px;
              &:hover{
                background: #E8EFFF ;
              }
            }

          }

          .sms {
            display: flex;

            .accout {
              width: 190px;
              height: 42px;
              background: #FFFFFF;
              border: 1px solid #C8C8C8;
              background: #FFFFFF;
              line-height: 42px;
              box-sizing: border-box;
              margin-bottom: 0;
            }

          }
        }

        .agreement {
          margin-top: 26px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 12px;
          .icon {
            width: 16px;
            height: 16px;
            display: block;
            margin-right: 8px;

            &.no {
              background: url("../assets/img/weixunzhong.png");
              background-repeat: no-repeat;
              background-size: 16px 16px;
            }

            &.yes {
              background: url("../assets/img/xunzhong.png");
              background-repeat: no-repeat;
              background-size: 16px 16px;
            }
          }

          .text {

            color: #3D7EFF;
          }
        }

        .btns {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 12px;
          color: #999999;
          margin-bottom: 12px;

          div {
            cursor: pointer;
          }

          div:hover {
            color: #3D7EFF;
          }

          .segmentation {
            width: 1px;
            height: 12px;
            background: #D9D9D9;
            margin: 0 15px;
          }
        }

        .btn {
          button {
            width: 100%;
            height: 42px;
            background: #3D7EFF;
            cursor: pointer;
            color: #FFFFFF;
            margin-top: 16px;

            &:hover {
              background: rgba(#3D7EFF, .7);
            }
          }
        }

        .switchover {
          text-align: center;
          font-size: 12px;
          color: rgba(#000000,0.45);
          cursor: pointer;
          margin-top: 16px;
          padding-bottom: 20px;

          span {
            color: #3D7EFF;
          }
        }
      }
    }

  }

  /deep/ .swiper-pagination {
    text-align: left;
  }

  /deep/ .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    background: #FFFFFF;

    border: 1px solid #C8C8C8;
    border-radius: 3px;
  }

  /deep/ .swiper-pagination-bullet-active {
    background: #ffffff;
    border: 1px solid #C8C8C8;
  }
}

input {
  flex: 1;
  height: 100%;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  border: none !important;
}
</style>
